export const ORMURL = () => {
  // fetch dashboard url and return each respective orm url
  const hostname = window.location.hostname;
  const isLocalhost = hostname.startsWith('localhost');
  const isDev = import.meta.env.DEV;
  let baseUrl = `https://${hostname}`;
  if (isDev || isLocalhost) {
    return { Prod: 'http://localhost:8080/', baseUrl: 'http://localhost:8080' };
  }
  return { Prod: `${baseUrl}/orm/`, baseUrl };
};

const checkIfScriptLoaded = (url) => {
  const library = document.querySelector(`script[src="${url}"]`);

  return library;
};

const loadScript = (src) => {
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = src;
    script.async = true;

    script.onload = () => resolve();
    script.onerror = () => reject(new Error(`Failed to load script: ${src}`));

    document.body.appendChild(script);
  });
};

export const liveServerName = 'live.mixl.ai';

export const meetingLibUrl = `https://${liveServerName}/external_api.js`;

export const loadMeetingLibrary = async () => {
  const scriptLoaded = checkIfScriptLoaded(meetingLibUrl);
  if (scriptLoaded) {
    console.log('meeting module already present');
    return true;
  }
  try {
    await loadScript(meetingLibUrl);
    console.log('meeting module loaded successfully!');
    return true;
  } catch (error) {
    console.log('Err: unable to load meeting module ', error);
    return false;
  }
};

export const defaultAppRoute = '/mixlearn';
// export const aiAgentUrl = 'https://app.mixl.ai/mixl_agent';
export const aiAgentUrl = import.meta.env.DEV ? 'http://localhost:8081' : 'https://app.mixl.ai/mixl_agent';