import { updateProfile } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

import { firebaseAuth, firebaseDB, getUserToken } from '../../components/firebase/firebase';
import Request from '../../Services/Request';
import { Sentry } from '../../Services/SentryLogger';
import { IMixlSubscription, ISlackCredential } from '../../Types/mixlTypes';
import { isExtenstionSidebar, postIframeMessage } from '../../utils/platformUtils';
import { getSlackChannelList } from '../mixl/functions';
import { RootState } from '../root-reducer';
import { gloqalBusiness } from './type';

const { PutData, GetSecuredData } = Request();

function updateProjectManager(platform: string, enabled: boolean) {
  if (isExtenstionSidebar()) {
    if (enabled) {
      postIframeMessage({ type: 'add_project_manager', platform, enabled });
    } else {
      postIframeMessage({ type: 'remove_project_manager', platform, enabled });
    }
  }
}

export const shouldShowTermsModal = (value: boolean) => {
  return {
    type: gloqalBusiness.SHOW_TERMS_MODAL,
    value
  };
};

export const setAuthInitialised = () => async (dispatch, getState) => {
  const payload = {};
  const store: RootState = getState();
  const { businessData } = store;
  console.log('setAuthInitialised: window.location.search', window.location.search);
  const queryParam = new URLSearchParams(window.location.search);
  const extensionId = queryParam.get('extensionId') || businessData.extensionId;
  if (extensionId) {
    payload['extensionId'] = extensionId;
    const currentUser = firebaseAuth.currentUser;
    console.log('setAuthInitialised: extensionId', extensionId, currentUser);
    if (currentUser) {
      // verify extension id
      const docRef = doc(firebaseDB, 'app_config', 'info');
      const docSnap = await getDoc(docRef);
      const data = docSnap.data();
      console.log('setAuthInitialised: data', data);
      const extensionIds = data?.extension_ids;
      console.log('setAuthInitialised: extensionIds', extensionIds);
      const isExtensionIdValid = extensionIds?.includes(extensionId);
      console.log('setAuthInitialised: isExtensionIdValid', isExtensionIdValid);
      const chrome = window.chrome;
      if (isExtensionIdValid && chrome?.runtime?.sendMessage) {
        const authToken = currentUser?.stsTokenManager?.refreshToken;
        if (authToken) {
          const response = await chrome.runtime.sendMessage(extensionId, { type: 'user_auth', authToken });
          if (response && response.success) {
            console.log('setAuthInitialised: Data sent successfully:', response);
          } else {
            console.error('setAuthInitialised: Failed to send data');
          }
        }
      }
    }
  }
  dispatch({ type: gloqalBusiness.INIT_AUTH_STATE, payload });
};

export const setCompanyBrandName = (name) => {
  return {
    type: gloqalBusiness.BRAND_NAME,
    payload: name
  };
};

export const updateSelectedSlackChannel = (payload?: ISlackCredential) => (dispatch) => {
  dispatch({
    type: gloqalBusiness.UPDATE_SLACK_AUTH,
    payload
  });
};

export const updateSlackCredential = (payload?: ISlackCredential) => (dispatch) => {
  dispatch({
    type: gloqalBusiness.UPDATE_SLACK_AUTH,
    payload
  });

  if (payload) {
    const slackToken = payload?.authed_user?.access_token;
    if (slackToken) {
      getSlackChannelList(slackToken)
        .then((slackChannels) => {
          dispatch({
            type: gloqalBusiness.UPDATE_SLACK_AUTH,
            payload: { ...payload, slackChannels }
          });
        })
        .catch((err) => console.log('Err: unable to fetch slack channels ', err));
    }
  }
};

export const updatePlatformCredential = (platformName, credential) => (dispatch) => {
  switch (platformName) {
    case 'oura':
      dispatch(updateOuraCredential(credential));
      break;
    case 'clickup':
      dispatch(updateClickupCredential(credential));
      break;
    case 'slack':
      dispatch(updateSlackCredential(credential));
      break;
    case 'jira':
      dispatch(updateJiraCredential(credential));
      break;
    case 'gcal':
      dispatch(updateGcalCredential(credential));
      break;
    case 'notion':
      dispatch(updateNotionCredential(credential));
      break;

    default:
      console.log('unsupported platform');
      break;
  }
};

export const updateClickupCredential = (payload) => {
  const enabled = Boolean(payload);
  updateProjectManager('clickup', enabled);
  return {
    type: gloqalBusiness.UPDATE_CLICKUP_AUTH,
    payload
  };
};

export const updateOuraCredential = (payload) => {
  return {
    type: gloqalBusiness.UPDATE_OURA_AUTH,
    payload
  };
};
export const updateGcalCredential = (payload) => {
  return {
    type: gloqalBusiness.UPDATE_GCAL_AUTH,
    payload
  };
};

export const updateJiraCredential = (payload) => {
  const enabled = Boolean(payload);
  updateProjectManager('atlassian', enabled);
  return {
    type: gloqalBusiness.UPDATE_JIRA_AUTH,
    payload
  };
};

export const updateNotionCredential = (payload) => {
  const enabled = Boolean(payload);
  updateProjectManager('notion', enabled);
  return {
    type: gloqalBusiness.UPDATE_NOTION_AUTH,
    payload
  };
};

export const setCompanyDetails = (payload: Record<string, unknown> | null) => {
  return async (dispatch) => {
    console.log('COMPANY_DETAILS: setCompanyDetails', payload);
    try {
      // todo: this is so weird logic, lets KIV for next refactor
      if (
        payload &&
        (payload.avatar ||
          payload.userName ||
          payload.workingStartTime ||
          payload.workingEndTime ||
          payload.mixlWorkTemplate)
      ) {
        const token = await getUserToken();
        await PutData(`moderator`, payload, token);
        dispatch({
          type: gloqalBusiness.COMPANY_DETAILS,
          payload
        });
      } else {
        const token = await getUserToken();
        const data = await GetSecuredData(`moderator`, token);
        console.log('COMPANY_DETAILS', data);
        dispatch({
          type: gloqalBusiness.COMPANY_DETAILS,
          payload: data.data
        });
      }
    } catch (error) {
      console.log('error: setCompanyDetails ', error);
      Sentry.captureException(error, {
        extra: { name: 'setCompanyDetails', payload }
      });
    }
  };
};

export const setMixlUserType = (isMixlLearnUser) => (dispatch) => {
  dispatch({ type: gloqalBusiness.COMPANY_DETAILS, payload: { isMixlLearnUser } });
};

export const toggelDeleteAccountModal = (isOpen) => {
  return {
    type: gloqalBusiness.SHOW_DELETE_ACCOUNT_MODAL,
    isOpen
  };
};

export const setProfilePicture = (payload: Record<string, unknown>): void => {
  console.log('COMPANY_DETAILS: setProfilePicture', payload);
  return async (dispatch) => {
    try {
      dispatch({
        type: gloqalBusiness.COMPANY_DETAILS,
        payload
      });
    } catch (error) {
      console.log('error: setCompanyDetails ', error);
      Sentry.captureException(error, {
        extra: { name: 'setCompanyDetails', payload }
      });
    }
  };
};

export const setOpenUploadPhotoModal = (payload) => {
  return function (dispatch, getState) {
    const { showTermsModal } = getState().businessData;
    if (showTermsModal) {
      return;
    }
    dispatch({
      type: gloqalBusiness.UPLOAD_PHOTO_MODAL,
      payload
    });
  };
};

export const setIsFirstTimeUser = (payload) => {
  return function (dispatch) {
    dispatch({
      type: gloqalBusiness.SET_FIRST_TIME_USER,
      payload
    });
  };
};

export const setActiveSwiperIndex = (payload) => {
  return function (dispatch) {
    dispatch({
      type: gloqalBusiness.SET_ACTIVE_INDEX,
      payload
    });
  };
};

export const updateSubscriptionStatus = (isPremiumUser) => (dispatch) => {
  dispatch({
    type: gloqalBusiness.SET_HAS_PREMIUM_SUBSCRIPTION,
    payload: isPremiumUser
  });
};

export const upgradeSubscription = (payload: IMixlSubscription) => async (dispatch, getState) => {
  const store: RootState = getState();
  const isPremiumUser = store.businessData.isPremiumUser;
  if (isPremiumUser) {
    return;
  }
  const token = await getUserToken();
  const { data: userData } = await GetSecuredData(`moderator`, token);
  const subscriptionExpireDate = userData?.mixlSubscription?.expireDate;
  if (subscriptionExpireDate > 0) {
    if (subscriptionExpireDate > Date.now()) {
      // restores previous subscription
      dispatch(updateSubscriptionStatus(true));
    }
    return;
  }
  await PutData('moderator', { mixlSubscription: payload }, token).catch((err) => {
    console.log('err: unable to upgrade subscription ', err);
  });
  dispatch({
    type: gloqalBusiness.SET_HAS_PREMIUM_SUBSCRIPTION,
    payload: true
  });
};

export const updateShowInviteModal = (payload: boolean) => async (dispatch) => {
  dispatch({
    type: gloqalBusiness.SET_SHOW_INVITE_MODAL,
    payload
  });
};

export const updateUserProfileInfo = (name: string, avatar: string) => async (dispatch) => {
  const user = firebaseAuth.currentUser;
  await updateProfile(user, {
    displayName: name,
    photoURL: avatar
  });
  dispatch(
    setCompanyDetails({
      userName: name || '',
      name: name || '',
      nameLowercase: name || '',
      avatar: avatar || '',
      stageImage: avatar || ''
    })
  );
  dispatch(
    setProfilePicture({
      resizedImages: {
        small: avatar,
        medium: avatar
      }
    })
  );
};

export const setShowProfileModal = (payload) => {
  return function (dispatch) {
    dispatch({
      type: gloqalBusiness.SET_SHOW_PROFILE,
      payload
    });
  };
};
